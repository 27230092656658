interface TicketOption {
  value: string
  title: string
}

export const ticketStatuses: TicketOption[] = [
  { value: "open", title: "Open" },
  { value: "closed", title: "Closed" },
  { value: "on_hold", title: "On Hold" },
  { value: "in_progress", title: "In Progress" },
  { value: "awaiting_response", title: "Awaiting Response" },
  { value: "in_planning", title: "In Planning" },
  { value: "resolved", title: "Resolved" },
]

export const ticketCategories: TicketOption[] = [
  { value: "triage", title: "Triage" },
  { value: "it_problem", title: "IT Problem" },
  { value: "it_request", title: "IT Request" },
  { value: "it_task", title: "IT Task" },
  { value: "connect_problem", title: "Connect Problem" },
  { value: "connect_request", title: "Connect Request" },
  { value: "connect_task", title: "Connect Task" },
  { value: "feedback", title: "Feedback" },
  { value: "other", title: "Other" },
]

export const ticketPriorities: TicketOption[] = [
  { value: "low", title: "Low" },
  { value: "normal", title: "Normal" },
  { value: "high", title: "High" },
  { value: "urgent", title: "Urgent" },
]

export const ticketStatusColor = (status: string): string => {
  switch (status) {
    case "open":
      return "blue"
    case "closed":
      return "grey"
    case "awaiting_response":
      return "orange"
    case "on_hold":
      return "red"
    case "resolved":
      return "green"
    case "in_planning":
      return "#5bc0de"
    case "investigating":
      return "#f0ad4e"
    case "in_progress":
      return "cyan"
    default:
      return "blue"
  }
}

export const ticketPriorityColor = (priority: string): string => {
  switch (priority) {
    case "low":
      return "grey"
    case "normal":
      return "blue"
    case "high":
      return "orange"
    case "urgent":
      return "red"
    default:
      return "blue"
  }
}

//add category for connect_request and it_task
export const ticketCategoryColor = (category: string): string => {
  switch (category) {
    case "triage":
      return "red";
    case "frontend":
      return "blue";
    case "backend":
      return "green";
    case "frontend_legacy":
      return "purple";
    case "backend_legacy":
      return "orange";
    case "connect_request":
      return "cyan";
    case "connect_problem":
      return "magenta";
    case "it_request":
      return "#e0bc00";
    case "it_problem":
      return "brown";
    case "it_task":
      return "teal";
    default:
      return "blue";
  }

}
